exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-contact-thanks-index-jsx": () => import("./../../../src/pages/contact/thanks/index.jsx" /* webpackChunkName: "component---src-pages-contact-thanks-index-jsx" */),
  "component---src-pages-gallery-index-jsx": () => import("./../../../src/pages/gallery/index.jsx" /* webpackChunkName: "component---src-pages-gallery-index-jsx" */),
  "component---src-pages-menu-index-jsx": () => import("./../../../src/pages/menu/index.jsx" /* webpackChunkName: "component---src-pages-menu-index-jsx" */),
  "component---src-pages-message-index-jsx": () => import("./../../../src/pages/message/index.jsx" /* webpackChunkName: "component---src-pages-message-index-jsx" */),
  "component---src-pages-point-index-jsx": () => import("./../../../src/pages/point/index.jsx" /* webpackChunkName: "component---src-pages-point-index-jsx" */),
  "component---src-pages-recruit-index-jsx": () => import("./../../../src/pages/recruit/index.jsx" /* webpackChunkName: "component---src-pages-recruit-index-jsx" */),
  "component---src-pages-shop-index-jsx": () => import("./../../../src/pages/shop/index.jsx" /* webpackChunkName: "component---src-pages-shop-index-jsx" */),
  "component---src-pages-staff-index-jsx": () => import("./../../../src/pages/staff/index.jsx" /* webpackChunkName: "component---src-pages-staff-index-jsx" */),
  "component---src-pages-work-barber-assistant-full-kanazawa-index-jsx": () => import("./../../../src/pages/work/barber-assistant-full-kanazawa/index.jsx" /* webpackChunkName: "component---src-pages-work-barber-assistant-full-kanazawa-index-jsx" */),
  "component---src-pages-work-barber-stylist-full-kanazawa-index-jsx": () => import("./../../../src/pages/work/barber-stylist-full-kanazawa/index.jsx" /* webpackChunkName: "component---src-pages-work-barber-stylist-full-kanazawa-index-jsx" */),
  "component---src-pages-work-barber-stylist-part-takaoka-index-jsx": () => import("./../../../src/pages/work/barber-stylist-part-takaoka/index.jsx" /* webpackChunkName: "component---src-pages-work-barber-stylist-part-takaoka-index-jsx" */),
  "component---src-pages-work-barber-stylist-part-toyama-index-jsx": () => import("./../../../src/pages/work/barber-stylist-part-toyama/index.jsx" /* webpackChunkName: "component---src-pages-work-barber-stylist-part-toyama-index-jsx" */),
  "component---src-pages-work-eyelist-full-kahoku-index-jsx": () => import("./../../../src/pages/work/eyelist-full-kahoku/index.jsx" /* webpackChunkName: "component---src-pages-work-eyelist-full-kahoku-index-jsx" */),
  "component---src-pages-work-eyelist-full-kanazawa-index-jsx": () => import("./../../../src/pages/work/eyelist-full-kanazawa/index.jsx" /* webpackChunkName: "component---src-pages-work-eyelist-full-kanazawa-index-jsx" */),
  "component---src-pages-work-hairdresser-stylist-full-kanazawa-index-jsx": () => import("./../../../src/pages/work/hairdresser-stylist-full-kanazawa/index.jsx" /* webpackChunkName: "component---src-pages-work-hairdresser-stylist-full-kanazawa-index-jsx" */),
  "component---src-pages-work-hairdresser-stylist-full-nakaniikawa-index-jsx": () => import("./../../../src/pages/work/hairdresser-stylist-full-nakaniikawa/index.jsx" /* webpackChunkName: "component---src-pages-work-hairdresser-stylist-full-nakaniikawa-index-jsx" */),
  "component---src-pages-work-hairdresser-stylist-full-nonoichi-index-jsx": () => import("./../../../src/pages/work/hairdresser-stylist-full-nonoichi/index.jsx" /* webpackChunkName: "component---src-pages-work-hairdresser-stylist-full-nonoichi-index-jsx" */),
  "component---src-pages-work-hairdresser-stylist-part-kahoku-index-jsx": () => import("./../../../src/pages/work/hairdresser-stylist-part-kahoku/index.jsx" /* webpackChunkName: "component---src-pages-work-hairdresser-stylist-part-kahoku-index-jsx" */),
  "component---src-pages-work-hairdresser-stylist-part-kanazawa-index-jsx": () => import("./../../../src/pages/work/hairdresser-stylist-part-kanazawa/index.jsx" /* webpackChunkName: "component---src-pages-work-hairdresser-stylist-part-kanazawa-index-jsx" */),
  "component---src-pages-work-hairdresser-stylist-part-nakaniikawa-index-jsx": () => import("./../../../src/pages/work/hairdresser-stylist-part-nakaniikawa/index.jsx" /* webpackChunkName: "component---src-pages-work-hairdresser-stylist-part-nakaniikawa-index-jsx" */),
  "component---src-pages-work-hairdresser-stylist-part-nonoichi-index-jsx": () => import("./../../../src/pages/work/hairdresser-stylist-part-nonoichi/index.jsx" /* webpackChunkName: "component---src-pages-work-hairdresser-stylist-part-nonoichi-index-jsx" */),
  "component---src-pages-work-hairdresser-stylist-part-takaoka-index-jsx": () => import("./../../../src/pages/work/hairdresser-stylist-part-takaoka/index.jsx" /* webpackChunkName: "component---src-pages-work-hairdresser-stylist-part-takaoka-index-jsx" */),
  "component---src-pages-work-hairdresser-stylist-part-toyama-index-jsx": () => import("./../../../src/pages/work/hairdresser-stylist-part-toyama/index.jsx" /* webpackChunkName: "component---src-pages-work-hairdresser-stylist-part-toyama-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-2-about-index-jsx": () => import("./../../../src/templates/2_about/index.jsx" /* webpackChunkName: "component---src-templates-2-about-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */),
  "component---src-templates-6-contact-index-jsx": () => import("./../../../src/templates/6_contact/index.jsx" /* webpackChunkName: "component---src-templates-6-contact-index-jsx" */)
}

